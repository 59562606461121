import { useEffect, useState } from "react";
import { useAuthContext } from "../AuthContext";
import {
  Dimensions,
  Image,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type MenuStackParamList } from "./MenuStack";
import styles from "../styles";
import { type BottomTabNestedStackScreenProps } from "../navigation/NavigationHelper";
import { Link } from "@react-navigation/native";

type Props = NativeStackScreenProps<MenuStackParamList, "AboutDialogScreen">;

export const AboutDialogScreen: React.FC<
  BottomTabNestedStackScreenProps<Props>
> = ({ navigation }) => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get("window").width,
  );
  const { requiresLogin, setRequiresLogin } = useAuthContext();

  const { user } = useAuthContext();

  useEffect(() => {
    if (requiresLogin) {
      setRequiresLogin(false);
    }

    const onChange = (): void => {
      setScreenWidth(Dimensions.get("window").width);
    };

    Dimensions.addEventListener("change", onChange);
  }, []);

  useEffect(() => {
    if (!user) {
      navigation.setOptions({
        headerRight: () => (
          <View style={styles.topMenuContainer}>
            <Pressable
              onPress={() => {
                setRequiresLogin(true);
              }}
            >
              <Text style={styles.moreMenuLinkText}>Sign In / Sign Up</Text>
            </Pressable>
          </View>
        ),
      });
    }
  }, [user]);

  const getFontSize = (): number => {
    if (screenWidth < 400) {
      return 24;
    } else if (screenWidth < 600) {
      return 28;
    } else {
      return 32;
    }
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        flexDirection: "column",
        padding: 20,
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fontSize: getFontSize(),
          textAlign: "center",
          marginVertical: 10,
        }}
      >
        Dialog is a place for one-on-one conversations between people with
        different perspectives.
      </Text>
      <Image
        source={require("../../assets/one-on-one-dialog.png")}
        resizeMode="contain"
        style={{
          ...styles.aboutImage,
          width: screenWidth - 40,
        }}
      ></Image>
      <Text
        style={{
          fontSize: getFontSize(),
          textAlign: "center",
          marginVertical: 10,
        }}
      >
        Because all too often, our communities look like this...
      </Text>
      <Image
        source={require("../../assets/echo-chamber.png")}
        resizeMode="contain"
        style={{
          ...styles.aboutImage,
          width: screenWidth - 40,
        }}
      ></Image>
      <Text
        style={{
          fontSize: getFontSize(),
          textAlign: "center",
          marginVertical: 10,
        }}
      >
        ...and online communities looks like this
      </Text>
      <Image
        source={require("../../assets/point-scoring.png")}
        resizeMode="contain"
        style={{
          ...styles.aboutImage,
          width: screenWidth - 40,
        }}
      ></Image>
      <Text
        style={{
          fontSize: getFontSize(),
          textAlign: "center",
          marginVertical: 10,
        }}
      >
        {
          'Dialog fosters good-faith, open-minded communication by limiting "dialogs" to two people and by keeping dialogs private unless both participants chose to share them publicly'
        }
      </Text>
      <Text
        style={{
          fontSize: getFontSize(),
          textAlign: "center",
          marginVertical: 10,
        }}
      >
        {
          "Unlike public forums and commenting platforms, Dialog encourages sharing and learning rather dunking and trolling"
        }
      </Text>
      <Text
        style={{
          fontSize: getFontSize() + 10,
          textAlign: "center",
          marginTop: 10,
          fontWeight: "500",
        }}
      >
        Try it out:
      </Text>
      <Text
        style={{
          fontSize: getFontSize(),
          textAlign: "center",
          marginVertical: 10,
        }}
      >
        <Link to="/open-dialogs" style={{ color: "blue" }}>
          Respond to an open dialog
        </Link>{" "}
        or{" "}
        <Link to="/start-dialog" style={{ color: "blue" }}>
          request a new dialog
        </Link>
      </Text>
    </ScrollView>
  );
};
