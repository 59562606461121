import { View, Text, Pressable } from "react-native";
import styles from "../styles";
import {
  type ConversationSearchItem,
  ConversationUserRole,
} from "../client/DialogClientModels";
import { ProfileIcon } from "../common/ProfileIcon";

type Props = {
  item: ConversationSearchItem;
  handleItemPress: (item: ConversationSearchItem) => void;
};

export const OpenDialogListItem: React.FC<Props> = ({
  item,
  handleItemPress,
}) => {
  const { title, users } = item;

  const creator = users.find(
    ({ role }) => role === ConversationUserRole.CREATOR,
  );

  const topicText = `${creator?.name ?? "?"} wants a dialog with ${title}`;

  return (
    <Pressable
      onPress={() => {
        handleItemPress(item);
      }}
    >
      <View style={styles.DialogListItem}>
        <ProfileIcon
          name={creator?.name ?? "?"}
          pictureUrl={creator?.pictureUrl}
        />
        <Text style={styles.topicText}>{topicText}</Text>
      </View>
    </Pressable>
  );
};
