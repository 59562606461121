import { useState, useEffect } from "react";
import { FlatList, Pressable, Text, View } from "react-native";
import { OpenDialogListItem } from "./OpenDialogListItem";
import { type ConversationSearchItem } from "../client/DialogClientModels";
import { type OpenDialogStackParamList } from "./OpenDialogsStack";
import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { SearchBar } from "../common/SearchBar";
import { useDialogApiContext } from "../DialogApiContext";
import { ErrorMessage } from "../common/ErrorMessage";
import { Feather } from "@expo/vector-icons";
import styles from "../styles";
import { type BottomTabNestedStackScreenProps } from "../navigation/NavigationHelper";
import { WelcomeSlideUp } from "./WelcomeSlideUp";

const BATCH_SIZE = 20;

type Props = NativeStackScreenProps<
  OpenDialogStackParamList,
  "OpenDialogListScreen"
>;

export type SlideUpMessage = "welcome" | undefined;

export const OpenDialogListScreen: React.FC<
  BottomTabNestedStackScreenProps<Props>
> = ({ route, navigation }) => {
  const slideUp = route.params?.slideUp;

  const [conversations, setConversations] = useState<ConversationSearchItem[]>(
    [],
  );
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { dialogClient } = useDialogApiContext();

  useEffect(() => {
    const StartDialogButton: React.FC = () => {
      return (
        <Pressable
          style={styles.topMenuButton}
          onPress={() => {
            navigation.navigate("StartDialog");
          }}
        >
          <Feather name="plus" size={20} style={styles.topMenuIcon} />
          <Text style={styles.topMenuText}>New Dialog</Text>
        </Pressable>
      );
    };

    navigation.setOptions({
      headerRight: () => (
        <View style={styles.topMenuContainer}>
          <StartDialogButton />
        </View>
      ),
    });
    fetchDataSync();
  }, []);

  const fetchData = async (refresh = false, query?: string): Promise<void> => {
    if (isLoading) return;

    if (refresh) {
      setConversations([]);
    }

    setIsLoading(true);

    try {
      const cursor = refresh ? undefined : nextCursor;

      const data = await dialogClient.searchOpenConversations(
        query,
        BATCH_SIZE,
        cursor,
      );

      // Randomly sort each batch of conversations, so different conversations get top billing
      const shuffledConversations = data.records.toSorted(
        () => 0.5 - Math.random(),
      );

      // Update the request data and nextCursor
      if (refresh) {
        setConversations(shuffledConversations);
      } else {
        setConversations([...conversations, ...shuffledConversations]);
      }
      setNextCursor(data.nextCursor);

      setErrorMessage(undefined);

      if (query) {
        setIsSearchResults(true);
        if (shuffledConversations.length === 0) {
          setErrorMessage(`No results found for "${query}"`);
        }
      } else {
        setIsSearchResults(false);
      }
    } catch (error) {
      setErrorMessage("Could not load open dialogs");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataSync = (refresh = false, query?: string): void => {
    fetchData(refresh, query).catch(console.error);
  };

  const handleItemPress = (item: ConversationSearchItem): void => {
    const { conversationTopicId, conversationId, title } = item;
    navigation.navigate("OpenDialogScreen", {
      conversationTopicId,
      conversationId,
      title,
    });
  };

  const renderDialogListItem = ({
    item,
  }: {
    item: ConversationSearchItem;
  }): React.JSX.Element => {
    return (
      <OpenDialogListItem
        item={item}
        handleItemPress={handleItemPress}
      ></OpenDialogListItem>
    );
  };

  const handleEndReached = (): void => {
    // Fetch more data when reaching the end of the list
    if (nextCursor) {
      if (searchQuery === "") {
        fetchDataSync();
      } else {
        fetchDataSync(false, searchQuery);
      }
    }
  };

  const handleSearch = (): void => {
    fetchDataSync(true, searchQuery);
  };

  const handleChangeSearchText = (text: string): void => {
    setSearchQuery(text);
    if (text === "" && isSearchResults) {
      fetchDataSync(true);
    }
  };

  const setSlideUp = (message: SlideUpMessage): void => {
    navigation.setParams({ slideUp: message });
  };

  return (
    <View style={{ flex: 1 }}>
      <SearchBar
        query={searchQuery}
        placeholder="Search Open Dialogs..."
        handleChangeSearchText={handleChangeSearchText}
        handleSearch={handleSearch}
      />
      {errorMessage && (
        <View style={{ marginTop: 10, maxWidth: 1000, alignSelf: "center" }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
      <FlatList
        data={conversations}
        renderItem={renderDialogListItem}
        keyExtractor={(item) => item.title}
        onEndReached={handleEndReached}
        onEndReachedThreshold={0.1}
        refreshing={isLoading}
        onRefresh={() => {
          fetchDataSync(true);
        }}
      />
      <WelcomeSlideUp
        setIsOpen={(isOpen: boolean) => {
          setSlideUp(isOpen ? "welcome" : undefined);
        }}
        isOpen={slideUp === "welcome"}
      />
    </View>
  );
};
