import axios from "axios";
import { fetchAuthSession } from "@aws-amplify/auth";
import {
  type CreateTopicResponse,
  type SearchConversationsResponse,
  type ConversationMessageResponse,
  type GetMessagesResponse,
  type GetConversationResponse,
  type ConversationReportContext,
  type ConversationVote,
  type EmailNotificationTopic,
} from "./DialogClientModels";
import * as Qs from "qs";

const DIALOG_API_BASE_URL = process.env.DIALOG_API_BASE_URL ?? "";

export class DialogClient {
  constructor(
    private readonly axiosInstance = axios.create({
      baseURL: DIALOG_API_BASE_URL,
    }),
  ) {
    this.axiosInstance.interceptors.request.use(async (config) => {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken;

      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken.toString()}`;
      }
      return config;
    });
  }

  public async getConversation(
    conversationTopicId: string,
    conversationId: string,
    messageLimit?: number,
    messageSort?: "ascending" | "descending",
  ): Promise<GetConversationResponse> {
    return this.get<GetConversationResponse>(
      `/conversation/topic/${conversationTopicId}/conversation/${conversationId}`,
      { messageLimit, messageSort },
    );
  }

  public async searchMyConversations(
    query?: string,
    limit?: number,
    sort?: "ascending" | "descending",
    cursor?: string,
  ): Promise<SearchConversationsResponse> {
    return this.get<SearchConversationsResponse>(
      "/conversation/conversations",
      { query, limit, sort, cursor },
    );
  }

  public async searchOpenConversations(
    query?: string,
    limit?: number,
    cursor?: string,
  ): Promise<SearchConversationsResponse> {
    return this.get<SearchConversationsResponse>("/public/open-conversations", {
      query,
      limit,
      cursor,
    });
  }

  public async searchPublicConversations(
    query?: string,
    rankByScoreName?: "NEW" | "RECENT",
    limit?: number,
    sort?: "ascending" | "descending",
    cursor?: string,
  ): Promise<SearchConversationsResponse> {
    return this.get<SearchConversationsResponse>(
      "/public/public-conversations",
      {
        query,
        rankByScoreName,
        limit,
        sort,
        cursor,
      },
    );
  }

  public async createTopic(
    title: string,
    reCaptchaToken?: string,
  ): Promise<CreateTopicResponse> {
    return this.post<CreateTopicResponse, { title: string }>(
      "/conversation/topic",
      { title },
      undefined,
      { "x-recaptcha-token": reCaptchaToken },
    );
  }

  public async respondToConversationWithTitle(
    title: string,
    reCaptchaToken?: string,
  ): Promise<{ conversationTopicId: string; conversationId: string }> {
    return this.put<
      { conversationTopicId: string; conversationId: string },
      undefined
    >(
      "/conversation/open-topic/responder",
      undefined,
      { title },
      { "x-recaptcha-token": reCaptchaToken },
    );
  }

  public async respondToConversation(
    conversationTopicId: string,
    conversationId: string,
    messageContent: string,
    reCaptchaToken?: string,
  ): Promise<void> {
    return this.put<undefined, { message: { content: string } }>(
      `/conversation/topic/${conversationTopicId}/conversation/${conversationId}/responder`,
      { message: { content: messageContent } },
      undefined,
      { "x-recaptcha-token": reCaptchaToken },
    );
  }

  public async getMessagesInConversation(
    conversationTopicId: string,
    conversationId: string,
    limit = 100,
    sort: "descending" | "ascending" = "descending",
    cursor?: string,
  ): Promise<GetMessagesResponse> {
    return this.get<GetMessagesResponse>(
      `/conversation/topic/${conversationTopicId}/conversation/${conversationId}/messages`,
      { limit, sort, cursor },
    );
  }

  public async getPublicConversation(
    conversationTopicId: string,
    conversationId: string,
  ): Promise<GetConversationResponse> {
    return this.get<GetConversationResponse>(
      `/public/topic/${conversationTopicId}/conversation/${conversationId}`,
    );
  }

  public async addConversationMessage(
    conversationTopicId: string,
    conversationId: string,
    content: string,
  ): Promise<ConversationMessageResponse> {
    return this.post<ConversationMessageResponse, { content: string }>(
      `/conversation/topic/${conversationTopicId}/conversation/${conversationId}/message`,
      { content },
    );
  }

  public async updateConversationUser(
    conversationTopicId: string,
    conversationId: string,
    update: { requestedPublicConversation: boolean },
  ): Promise<void> {
    return this.put<undefined, { requestedPublicConversation: boolean }>(
      `/conversation/topic/${conversationTopicId}/conversation/${conversationId}/user`,
      update,
    );
  }

  public async deleteConversation(
    conversationTopicId: string,
    conversationId: string,
  ): Promise<void> {
    return this.delete(
      `/conversation/topic/${conversationTopicId}/conversation/${conversationId}`,
    );
  }

  public async reportConversation(
    conversationTopicId: string,
    conversationId: string,
    reportContext: ConversationReportContext,
  ): Promise<void> {
    return this.put<undefined, { reportContext: ConversationReportContext }>(
      `/conversation/topic/${conversationTopicId}/conversation/${conversationId}/report`,
      { reportContext },
    );
  }

  public async reportConversationWithTitle(
    title: string,
    reportContext: ConversationReportContext,
  ): Promise<void> {
    return this.put<
      undefined,
      { title: string; reportContext: ConversationReportContext }
    >("/conversation/open-topic/report", { title, reportContext });
  }

  public async voteOnConversation(
    conversationTopicId: string,
    conversationId: string,
    vote: ConversationVote,
  ): Promise<void> {
    return this.put<undefined, undefined>(
      `/conversation/topic/${conversationTopicId}/conversation/${conversationId}/vote/${vote}`,
    );
  }

  public async getProfilePictureUploadUrl(): Promise<{
    signedUrl: string;
    key: string;
  }> {
    return this.get<{ signedUrl: string; key: string }>(
      "/user/profile-picture-url",
    );
  }

  public async saveProfilePictureUpload(
    key: string,
  ): Promise<{ pictureUrl: string }> {
    return this.put<{ pictureUrl: string }, { key: string }>(
      "/user/profile-picture",
      { key },
    );
  }

  public async updateBirthdate(birthdate: string): Promise<void> {
    return this.put<undefined, { birthdate: string }>("/user/birthdate", {
      birthdate,
    });
  }

  public async updateName(name: string): Promise<void> {
    return this.put<undefined, { name: string }>("/user/name", {
      name,
    });
  }

  public async getEmailNotificationSettings(): Promise<{
    [key in EmailNotificationTopic]: boolean;
  }> {
    return this.get<{ [key in EmailNotificationTopic]: boolean }>(
      "/user/email-notification-settings",
    );
  }

  public async updateEmailNotificationSetting(
    topic: EmailNotificationTopic,
    isEnabled: boolean,
  ): Promise<void> {
    return this.put<undefined, { isEnabled: boolean }>(
      "/user/email-notification-settings",
      { isEnabled },
      { topic },
    );
  }

  private async get<T>(path: string, params?: Record<string, any>): Promise<T> {
    return this.axiosInstance
      .get(path, {
        params,
        paramsSerializer: (params) =>
          Qs.stringify(params, { arrayFormat: "repeat" }),
      })
      .then((response) => response.data);
  }

  private async post<T, B>(
    path: string,
    body?: B,
    params?: Record<string, any>,
    headers?: Record<string, any>,
  ): Promise<T> {
    return this.axiosInstance
      .post(path, body, {
        params,
        headers,
        paramsSerializer: (params) =>
          Qs.stringify(params, { arrayFormat: "repeat" }),
      })
      .then((response) => response.data);
  }

  private async put<T, B>(
    path: string,
    body?: B,
    params?: Record<string, any>,
    headers?: Record<string, any>,
  ): Promise<T> {
    return this.axiosInstance
      .put(path, body, {
        params,
        headers,
        paramsSerializer: (params) =>
          Qs.stringify(params, { arrayFormat: "repeat" }),
      })
      .then((response) => response.data);
  }

  private async delete<T>(
    path: string,
    params?: Record<string, any>,
  ): Promise<T> {
    return this.axiosInstance
      .delete(path, {
        params,
        paramsSerializer: (params) =>
          Qs.stringify(params, { arrayFormat: "repeat" }),
      })
      .then((response) => response.data);
  }
}
