import { View } from "react-native";
import {
  type ConversationMetadataResponse,
  ConversationReportContext,
  ConversationStatus,
  type ConversationUserReportResponse,
  type ConversationUserResponse,
} from "../../client/DialogClientModels";
import styles from "../../styles";
import { MyDialogPublishMenu } from "./MyDialogPublishMenu";
import { MyDialogDeleteMenu } from "./MyDialogDeleteMenu";
import { ReportDialogMenu } from "../../common/ReportDialogMenu";

type Props = {
  metadata?: ConversationMetadataResponse;
  report?: ConversationUserReportResponse;
  users?: ConversationUserResponse[];
  userId?: string;
  fetchConversation: () => Promise<void>;
  setIsDeleted: () => void;
};

export const MyDialogTopMenu: React.FC<Props> = ({
  metadata,
  report,
  users,
  userId,
  fetchConversation,
  setIsDeleted,
}) => {
  if (!metadata || !users || !userId) {
    return <></>;
  }

  const otherUser = users.find((user) => user.userId !== userId);

  const reportMessageText = `Press "Report" to report ${otherUser?.name} for posting content that violates dialog's rules.`;

  switch (metadata.status) {
    case ConversationStatus.PRIVATE:
      if (report) {
        return <></>;
      }

      return (
        <View style={styles.topMenuContainer}>
          <MyDialogPublishMenu
            metadata={metadata}
            users={users}
            fetchConversation={fetchConversation}
          />
          <ReportDialogMenu
            metadata={metadata}
            reportContext={ConversationReportContext.PRIVATE}
            messageText={reportMessageText}
            fetchConversation={fetchConversation}
          />
        </View>
      );
    case ConversationStatus.PUBLIC:
      return (
        <View style={styles.topMenuContainer}>
          <MyDialogPublishMenu
            metadata={metadata}
            users={users}
            fetchConversation={fetchConversation}
          />
        </View>
      );
    case ConversationStatus.OPEN:
      return (
        <View style={styles.topMenuContainer}>
          <MyDialogDeleteMenu metadata={metadata} setIsDeleted={setIsDeleted} />
        </View>
      );
    default:
      return <></>;
  }
};
