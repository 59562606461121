import { type NativeStackScreenProps } from "@react-navigation/native-stack";
import { type OpenDialogStackParamList } from "./OpenDialogsStack";
import { type BottomTabNestedStackScreenProps } from "../navigation/NavigationHelper";
import { useDialogApiContext } from "../DialogApiContext";
import { useAuthContext } from "../AuthContext";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  ConversationReportContext,
  type GetConversationResponse,
} from "../client/DialogClientModels";
import { SignInToContinue } from "../common/SignInToContinue";
import { HeaderTitle } from "../common/HeaderTitle";
import {
  ActivityIndicator,
  Pressable,
  Text,
  TextInput,
  View,
} from "react-native";
import styles from "../styles";
import { ReportDialogMenu } from "../common/ReportDialogMenu";
import { Banner, BannerType } from "../common/Banner";
import { ReCaptchaButton } from "../common/Button";
import { ErrorMessage } from "../common/ErrorMessage";
import { GiftedAvatar } from "react-native-gifted-chat";

type Props = NativeStackScreenProps<
  OpenDialogStackParamList,
  "OpenDialogScreen"
>;

export const OpenDialogScreen: React.FC<
  BottomTabNestedStackScreenProps<Props>
> = ({ route, navigation }) => {
  const { dialogClient } = useDialogApiContext();
  const { user, fetchUserStatus } = useAuthContext();
  const userId = user?.userId;

  const { conversationTopicId, conversationId, title } = route.params;
  const [conversation, setConversation] = useState<GetConversationResponse>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [messageContent, setMessageContent] = useState<string>("");

  useLayoutEffect(() => {
    if (conversation) {
      setConversation(undefined);
      setMessageContent("");
    }

    if (userId) {
      fetchConversation().catch(console.error);
    }
  }, [conversationId, userId]);

  useEffect(() => {
    if (conversation) {
      navigation.setOptions({
        headerTitle: () => <HeaderTitle title={conversation.metadata.title} />,
        title: `Dialog with ${conversation.metadata.title}`,
        headerRight: () => (
          <View style={styles.topMenuContainer}>
            {conversation.report === undefined && (
              <ReportDialogMenu
                metadata={conversation.metadata}
                reportContext={ConversationReportContext.OPEN}
                fetchConversation={fetchConversation}
              />
            )}
          </View>
        ),
      });
    } else if (title) {
      navigation.setOptions({
        headerTitle: () => <HeaderTitle title={title} />,
        title: `Dialog with ${title}`,
      });
    }
  }, [conversation?.metadata, conversation?.report]);

  const fetchConversation = async (): Promise<void> => {
    try {
      const response = await dialogClient.getConversation(
        conversationTopicId,
        conversationId,
        0,
      );

      if (response.users.some((user) => user.userId === userId)) {
        navigation.navigate("MyDialogs", {
          screen: "MyDialogScreen",
          params: { conversationTopicId, conversationId, title },
        });
        return;
      }

      setConversation(response);
      setErrorMessage(undefined);
    } catch (error) {
      setErrorMessage("Could not load open dialog");
    }
  };

  const handleSubmit = async (reCaptchaToken?: string): Promise<void> => {
    if (!messageContent) {
      setErrorMessage("Please enter a message.");
      return;
    }

    try {
      await dialogClient.respondToConversation(
        conversationTopicId,
        conversationId,
        messageContent,
        reCaptchaToken,
      );

      setErrorMessage(undefined);

      navigation.navigate("MyDialogs", {
        screen: "MyDialogScreen",
        params: { conversationTopicId, conversationId, title },
      });
    } catch (error) {
      if (error.response.data.code === "CONVERSATION_LIMIT_REACHED") {
        const message =
          "You have reached your daily dialog limit." +
          "\nNew accounts may start or join 2 dialogs in a 24 hour period." +
          "\nAccounts at least one week old may start or join 10 dialogs every 24 hours.";

        setErrorMessage(message);
      } else {
        setErrorMessage(
          "This dialog is not available. Please try another dialog.",
        );
      }
    }
  };

  const handleReject = (): void => {
    navigation.navigate("OpenDialogs", {
      screen: "OpenDialogListScreen",
      params: { slideUp: undefined },
    });
  };

  if (fetchUserStatus === "PENDING") {
    return <></>;
  }

  if (!userId || fetchUserStatus !== "COMPLETE") {
    return <SignInToContinue action={"join a dialog"} />;
  }

  if (!conversation && !errorMessage) {
    return <ActivityIndicator size="small" style={{ margin: 20 }} />;
  }

  if (conversation?.report) {
    return (
      <View style={{ flex: 1 }}>
        <Banner
          message="You have reported this dialog. We will review it to determine the appropriate next steps. No further action is required from you."
          type={BannerType.ALERT}
        />
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      {errorMessage && (
        <View style={{ marginTop: 20, maxWidth: 1000, alignSelf: "center" }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
      {conversation && (
        <View style={styles.dialogResponseContainer}>
          <GiftedAvatar
            user={{
              _id: conversation.users[0].name,
              name: conversation.users[0].name,
              avatar: conversation.users[0].pictureUrl,
            }}
            avatarStyle={{ height: 100, width: 100, borderRadius: 50 }}
            textStyle={{ fontSize: 40 }}
          />
          <Text
            style={styles.dialogResponseTitle}
          >{`${conversation.users[0].name} wants a dialog with ${conversation.metadata.title}`}</Text>
          <Text
            style={styles.dialogResponseTitle}
          >{`If you are ${conversation.metadata.title}, send ${conversation.users[0].name} a message to kick off your dialog`}</Text>
          <TextInput
            style={styles.startDialogInput}
            placeholderTextColor={"gray"}
            placeholder={`write a message for ${conversation.users[0].name}`}
            onChangeText={(text) => {
              setMessageContent(text);
            }}
            multiline={true}
            numberOfLines={2}
            verticalAlign="top"
            maxLength={100}
            value={messageContent}
          />
          <ReCaptchaButton
            action="join_dialog"
            isDisabled={() => messageContent.length === 0}
            onPress={handleSubmit}
            buttonText="Join Dialog"
          />
          <Pressable onPress={handleReject} style={{ marginTop: 10 }}>
            <Text style={styles.alertLinkText}>Cancel</Text>
          </Pressable>
        </View>
      )}
    </View>
  );
};
