import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import type React from "react";
import { Feather } from "@expo/vector-icons";
import {
  type OpenDialogStackParamList,
  OpenDialogsStack,
} from "../open-dialogs/OpenDialogsStack";
import {
  type MyDialogStackParamList,
  MyDialogsStack,
} from "../my-dialogs/MyDialogsStack";
import { StartDialogScreen } from "../start-dialog/StartDialogScreen";
import {
  type PublicDialogStackParamList,
  PublicDialogsStack,
} from "../public-dialogs/PublicDialogsStack";
import { type NavigatorScreenParams } from "@react-navigation/native";
import { MenuStack, type MenuStackParamList } from "../menu/MenuStack";

export type BottomTabParamList = {
  OpenDialogs: NavigatorScreenParams<OpenDialogStackParamList>;
  MyDialogs: NavigatorScreenParams<MyDialogStackParamList>;
  StartDialog: undefined;
  PublicDialogs: NavigatorScreenParams<PublicDialogStackParamList>;
  More: NavigatorScreenParams<MenuStackParamList>;
};

const Tab = createBottomTabNavigator<BottomTabParamList>();

export const BottomTabNavigator: React.FC = () => {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ color, size }) => {
          let iconName:
            | "home"
            | "message-square"
            | "plus"
            | "globe"
            | "menu"
            | undefined;

          if (route.name === "OpenDialogs") {
            iconName = "home";
          } else if (route.name === "MyDialogs") {
            iconName = "message-square";
          } else if (route.name === "StartDialog") {
            iconName = "plus";
          } else if (route.name === "PublicDialogs") {
            iconName = "globe";
          } else if (route.name === "More") {
            iconName = "menu";
          }

          return <Feather name={iconName} size={size} color={color} />;
        },
        // tabBarShowLabel: route.name === "Menu",
      })}
    >
      <Tab.Screen
        name="OpenDialogs"
        component={OpenDialogsStack}
        listeners={({ navigation }) => ({
          tabPress: (event) => {
            event.preventDefault();
            navigation.navigate("OpenDialogs", {
              screen: "OpenDialogListScreen",
            });
          },
        })}
        options={{
          headerShown: false,
          tabBarLabel: "Join a Dialog",
        }}
      />
      <Tab.Screen
        name="MyDialogs"
        component={MyDialogsStack}
        listeners={({ navigation }) => ({
          tabPress: (event) => {
            event.preventDefault();
            navigation.navigate("MyDialogs", {
              screen: "MyDialogListScreen",
            });
          },
        })}
        options={{
          headerShown: false,
          tabBarLabel: "My Dialogs",
        }}
      />
      <Tab.Screen
        name="StartDialog"
        component={StartDialogScreen}
        options={{
          headerShown: false,
          tabBarLabel: "Start a Dialog",
          title: "Start a Dialog",
        }}
      />
      <Tab.Screen
        name="PublicDialogs"
        component={PublicDialogsStack}
        listeners={({ navigation }) => ({
          tabPress: (event) => {
            // Prevent default action
            event.preventDefault();
            navigation.navigate("PublicDialogs", {
              screen: "PublicDialogListScreen",
            });
          },
        })}
        options={{
          headerShown: false,
          tabBarLabel: "Public Dialogs",
        }}
      />
      <Tab.Screen
        name="More"
        component={MenuStack}
        listeners={({ navigation }) => ({
          tabPress: (event) => {
            event.preventDefault();
            navigation.navigate("More", {
              screen: "MenuScreen",
            });
          },
        })}
        options={{
          headerShown: false,
        }}
      />
    </Tab.Navigator>
  );
};
