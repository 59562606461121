import { View } from "react-native";
import {
  ConversationReportContext,
  type ConversationMetadataResponse,
  type ConversationUserReportResponse,
} from "../../client/DialogClientModels";
import styles from "../../styles";
import { PublicDialogShareMenu } from "../../public-dialogs/menu/PublicDialogShareMenu";
import { useAuthContext } from "../../AuthContext";
import { ReportDialogMenu } from "../../common/ReportDialogMenu";

type Props = {
  metadata?: ConversationMetadataResponse;
  report?: ConversationUserReportResponse;
  fetchConversation: () => Promise<void>;
};

export const PublicDialogTopMenu: React.FC<Props> = ({
  metadata,
  report,
  fetchConversation,
}) => {
  const { user } = useAuthContext();

  if (!metadata || report) {
    return <></>;
  }

  return (
    <View style={styles.topMenuContainer}>
      <PublicDialogShareMenu metadata={metadata} />
      {user && (
        <ReportDialogMenu
          metadata={metadata}
          reportContext={ConversationReportContext.PUBLIC}
          fetchConversation={fetchConversation}
        />
      )}
    </View>
  );
};
