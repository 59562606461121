import { useEffect, useState } from "react";
import { View, Text, TextInput } from "react-native";
import styles from "../styles";
import { type BottomTabNestedScreenProps } from "../navigation/NavigationHelper";
import { useAuthContext } from "../AuthContext";
import { ReCaptchaButton } from "../common/Button";
import { useDialogApiContext } from "../DialogApiContext";
import { SignInToContinue } from "../common/SignInToContinue";
import { ErrorMessage } from "../common/ErrorMessage";

const PLACEHOLDERS = [
  "a Democrat",
  "a Republican",
  "a bird watcher",
  "a marathon runner",
  "a combat veteran",
  "someone who lives in a big city",
  "someone who lives in the country",
  "someone who is pro-life",
  "someone who is pro-choice",
  "a vegan",
  "a hunter",
];

export const StartDialogScreen: React.FC<
  BottomTabNestedScreenProps<"StartDialog">
> = ({ navigation }) => {
  const [dialogTitle, setDialogTitle] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState<
    { index: number; characterIndex: number } | undefined
  >({ index: 0, characterIndex: 0 });
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const { setRequiresLogin, user, fetchUserStatus } = useAuthContext();

  useEffect(() => {
    navigation.setOptions({
      headerTitle: "Start a Dialog",
      headerShown: true,
    });
    setRequiresLogin(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPlaceholderIndex((prev) => {
        if (!prev) {
          return;
        }

        const placeholder = PLACEHOLDERS[prev.index];

        if (prev.characterIndex < placeholder.length) {
          return { index: prev.index, characterIndex: prev.characterIndex + 1 };
        } else if (prev.index < PLACEHOLDERS.length - 1) {
          return { index: prev.index + 1, characterIndex: 0 };
        } else {
          return { index: 0, characterIndex: 0 };
        }
      });
    }, getPlaceholderDelay());
  }, [placeholderIndex]);

  const { dialogClient } = useDialogApiContext();

  const handleSubmit = async (reCaptchaToken: string): Promise<void> => {
    setErrorMessage(undefined);
    const sanitizedTitle = dialogTitle
      .trim()
      .replace(/^A /, "a ")
      .replace(/^An /, "an ")
      .replace(/^Someone /, "someone ");

    try {
      const {
        metadata: { conversationTopicId, conversationId },
      } = await dialogClient.createTopic(sanitizedTitle, reCaptchaToken);

      setDialogTitle("");

      navigation.navigate("MyDialogs", {
        screen: "MyDialogScreen",
        params: { conversationTopicId, conversationId, title: sanitizedTitle },
      });
    } catch (err) {
      if (err.response.data.code === "CONVERSATION_LIMIT_REACHED") {
        const message =
          "You have reached your daily dialog limit." +
          "\nNew accounts may start or join 2 dialogs in a 24 hour period." +
          "\nAccounts at least one week old may start or join 10 dialogs every 24 hours.";

        setErrorMessage(message);
      } else {
        setErrorMessage("Error requesting dialog");
      }
    }
  };

  const getPlaceholderDelay = (): number => {
    if (!placeholderIndex) {
      return 0;
    }

    // Long delay after finishing placeholder
    if (
      PLACEHOLDERS[placeholderIndex.index]?.length ===
      placeholderIndex.characterIndex
    ) {
      return 1500;
    }

    // Short delay between characters while typing placeholder
    return 30;
  };

  const getPlaceholder = (): string => {
    if (!placeholderIndex) {
      return "";
    }

    return PLACEHOLDERS[placeholderIndex.index].slice(
      0,
      placeholderIndex.characterIndex,
    );
  };

  if (fetchUserStatus === "PENDING") {
    return <></>;
  }

  return user && fetchUserStatus === "COMPLETE" ? (
    <View style={styles.startDialogContainer}>
      <Text style={{ fontSize: 26, textAlign: "center", marginTop: 20 }}>
        Request a dialog with someone with a particular opinion, experience, or
        identity
      </Text>
      <Text style={styles.startDialogTitle}>I want a dialog with:</Text>
      <TextInput
        style={styles.startDialogInput}
        placeholder={getPlaceholder()}
        placeholderTextColor={"gray"}
        onChangeText={(text) => {
          setDialogTitle(text);
        }}
        onFocus={() => {
          setPlaceholderIndex(undefined);
        }}
        onBlur={() => {
          setPlaceholderIndex({ index: 0, characterIndex: 0 });
        }}
        multiline={true}
        numberOfLines={2}
        verticalAlign="top"
        maxLength={100}
        value={dialogTitle}
      />
      <ReCaptchaButton
        action="start_dialog"
        onPress={handleSubmit}
        buttonText="Request Dialog"
      />
      {errorMessage && (
        <View style={{ marginTop: 10 }}>
          <ErrorMessage errorMessage={errorMessage} />
        </View>
      )}
      <Text style={{ fontSize: 16, marginTop: 10 }}>
        {"We'll do our best to find a match"}
      </Text>
    </View>
  ) : (
    <SignInToContinue action="start a dialog" />
  );
};
