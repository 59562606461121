import { Text, View } from "react-native";
import { SlideUpMenuButton } from "./SlideUpMenuButton";
import { Feather } from "@expo/vector-icons";
import styles from "../styles";
import {
  type ConversationMetadataResponse,
  type ConversationReportContext,
} from "../client/DialogClientModels";
import { useState } from "react";
import { AsyncButton } from "./Button";
import { useDialogApiContext } from "../DialogApiContext";

export const ReportDialogMenu: React.FC<{
  metadata: ConversationMetadataResponse;
  reportContext: ConversationReportContext;
  messageText?: string;
  fetchConversation: () => Promise<void>;
}> = ({
  metadata,
  reportContext,
  messageText = 'Press "Report" to report this dialog for content that violates dialog\'s rules.',
  fetchConversation,
}) => {
  const { dialogClient } = useDialogApiContext();

  const [isOpen, setIsOpen] = useState(false);

  const ReportButton: React.FC = () => {
    return (
      <View style={styles.topMenuButton}>
        <Feather
          name="alert-triangle"
          size={20}
          style={styles.topMenuAlertIcon}
        />
        <Text style={styles.topMenuAlertText}>Report</Text>
      </View>
    );
  };

  const SlideUp: React.FC = () => {
    const onPress = async (): Promise<void> => {
      await dialogClient.reportConversation(
        metadata.conversationTopicId,
        metadata.conversationId,
        reportContext,
      );
      await fetchConversation();
      setIsOpen(false);
    };

    return (
      <View style={styles.slideUpContentContainer}>
        <Text style={styles.slideUpText}>{messageText}</Text>
        <AsyncButton onPress={onPress} isAlert buttonText="Report" />
      </View>
    );
  };

  return (
    <SlideUpMenuButton
      triggerComponent={<ReportButton />}
      contentComponent={<SlideUp />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
